import React from 'react';

import ReactGA from 'react-ga';

ReactGA.initialize('G-3N021SE7NV');
ReactGA.pageview(window.location.pathname + window.location.search);

const type = window.location.pathname.split('/')[1];
const id = window.location.pathname.split('/')[2];

const RedirectScreen = (props) => {
  const openDeepLink = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 200) return;
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        window.location =
          'https://play.google.com/apps/testing/com.trufflapp.truffl';
      } else {
        window.location =
          'https://apps.apple.com/in/app/truffl-trip-planner/id1526192968';
      }
    }, 25);
    if (type === 'trip') window.location = `truffl:///main/trip/${id}`;
    if (type === 'u') window.location = `truffl:///main/user/${id}`;
  };

  return (
    <div style={{ height: 'calc(100vh - 48px)', width: '100vw' }}>
      <div
        style={{
          backgroundColor: '#121212',
          height: 48,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          onClick={openDeepLink}
          style={{
            backgroundColor: 'transparent',
            border: 0,
            color: '#fcfcfc',
            fontSize: 16,
            fontWeight: '600',
            padding: 8,
            paddingTop: 10,
          }}
        >
          Open {type === 'trip' ? 'trip' : 'profile'} in app
        </button>
      </div>

      <iframe
        title="home"
        src="https://trufflapp.com/"
        height="100%"
        width="100%"
        frameBorder="0"
      />
    </div>
  );
};

export default RedirectScreen;
