import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RedirectScreen from './screens/Redirect';

function App() {
  const regex = new RegExp('(trip/|u/)([a-zA-Z0-9_]*)$');
  if (!regex.test(window.location.pathname))
    window.location = 'https://trufflapp.com';

  return (
    <Router>
      <Routes>
        <Route path="/trip/:id" element={<RedirectScreen />} />
        <Route path="/u/:id" element={<RedirectScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
